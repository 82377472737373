<template>
    <!--<v-card max-width="600" class="pa-4 grey darken-4">-->
    <div class="flex-grow-1">
        <v-scale-transition>
            <!--<v-container class="pa-4 grey darken-4" v-show="show">-->
            <v-row v-show="show" class="justify-center ma-0">
                <v-col cols="12" md="8" sm="10" lg="6" class="pa-0">
                    <v-toolbar dense dark color="blue-grey darken-4" elevation="0">
                        <v-toolbar-title>Бонусный счет</v-toolbar-title>
                    </v-toolbar>
                    <v-sheet elevation="12" class="blue-grey darken-3 pa-4">
                        <v-form class="pt-0"
                                ref="form"
                                v-model="valid"
                                lazy-validation
                        >
                            <v-row class="mb-n3">
                                <v-col cols="12" sm="6" class="mb-2">
                                    <v-select
                                            v-model="type"
                                            :items="typeItems"
                                            :rules="[v => !!v || 'Item is required']"
                                            label="Тип"
                                            required
                                            readonly
                                            hide-details
                                            outlined
                                            return-object
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" sm="6" class="mb-2">
                                    <v-select
                                            v-model="status"
                                            :items="statusItems"
                                            :rules="[v => !!v || 'Item is required']"
                                            label="Статус"
                                            required
                                            hide-details
                                            outlined
                                            return-object
                                    ></v-select>
                                </v-col>
                            </v-row>

                            <!--:counter="10"-->
                            <v-row class="mb-n4">
                                <v-col cols="12" sm="6" class="mb-n5">
                                    <v-text-field
                                            v-model="number"
                                            label="Номер счета"
                                            hint="Номер счета"
                                            persistent-hint
                                            outlined
                                            readonly
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" class="mb-2">
                                    <v-text-field
                                            v-model="balanceFormat"
                                            label="Баланс счета"
                                            hint="Баланс счета"
                                            persistent-hint
                                            outlined
                                            readonly
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <!--:counter="10"-->
                            <v-text-field
                                    class="mb-1"
                                    v-model="data"
                                    label="Дополнительная информация"
                                    hint="Дополнительная информация"
                                    persistent-hint
                                    outlined
                                    readonly
                            ></v-text-field>

                            <v-text-field
                                    class="mb-1"
                                    v-model="ref"
                                    :rules="[refValidate]"
                                    label="Ref"
                                    required
                                    outlined
                                    hint="Ref"
                                    persistent-hint
                                    readonly
                            ></v-text-field>

                            <v-row>
                                <v-col cols="12" sm="6" class="mb-2">
                                    <v-text-field
                                            v-model="createTime"
                                            label="Создан UTC"
                                            outlined
                                            readonly
                                            hide-details
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" class="mb-4">
                                    <v-text-field
                                            v-model="updateTime"
                                            label="Изменен UTC"
                                            outlined
                                            readonly
                                            hide-details
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                        </v-form>
                        <v-card-actions class="pa-0">
                            <v-btn color="primary" @click.stop :to="'/account/' + id + '/bank'" :disabled="!id">{{'Операции'}}</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn color="success" @click="submit" :disabled="!valid">{{!id ? 'Добавить' : 'Изменить'}}</v-btn>
                            <!--<v-btn color="primary" :disabled="!valid" type="submit">Login</v-btn>-->
                        </v-card-actions>
                        <!--</v-card>-->
                    </v-sheet>
                </v-col>
            </v-row>
            <!--</v-container>-->
        </v-scale-transition>
    </div>
</template>

<script>
  export default {
    name: "AccountForm",
    props: ['param', 'holder'],

    watch:{
      param:
        function(v){
          //console.log('AccountForm.vue watch param');
          this.init();
        },
    },
    computed: {
      balanceFormat(){
        //console.log(this.balance);
        return ( this.balance.value ? this.balance.balanceUnit + '.' + this.balance.balanceHundredths: '');
      }
    },

    data: () => ({
      valid: false,
      show: false,
      //disabled: true,

      id: null,
      holderId: null,
      number: '',
      balance: {},
      data: '',
      ref: '',
      type: {},
      typeItems: [
        {text: 'Бонусный', value:'bonus'},
      ],
      status: {},
      statusItems: [
        {text: 'Активен', value:'active'},
        {text: 'Отключен', value:'disabled'},
      ],
      createTime: '',
      updateTime: ''
    }),

    mounted(){
      this.init();
    },

    methods: {
      init(){
        //console.log('AccountForm.vue init()');
        this.show = true;
        //console.log('init');
        //console.log(this.refValidate('holder::multinet::100046'));
        if (!this.param && !!this.holder){
          this.id = null;
          this.holderId = this.holder;
          this.number = '';
          this.type = this.typeItems[0];
          this.status = this.statusItems[0];
          this.ref = 'account::' + this.id;
          //this.resetValidation();
          this.$nextTick(() => this.resetValidation());
          //this.$emit('update-title', 'Пользователь: Новый');
          //this.$emit('update-account', {title: '<Новый>'});
        }
        else if (!!this.param){
          this.id = this.param;
          //потом сделать наоборот, если цифра то гет данные иначе новый
          this.get();
        }
        else{
          this.$store.dispatch("showMessage", {text: 'Ошибка инициализации account', error: true});
        }
        //console.log(this.holder);
      },

      refValidate(value){
        //console.log('vata');
        //console.log(value);
        return ( !value || /account::(\d)+$/.test(value))
                || 'Недопустимые символы';
      },

      get(){
        //console.log('AccountForm.vue get()');
        this.$store.dispatch("setLoading", true);
        try{
          this.$store.dispatch("getHolderAccount", this.id)
            .then((response) =>{
              //console.log(response);
              if (response.data){
                this.holderId = response.data.holderId;
                this.number = response.data.number;
                this.balance = response.data.balance;
                this.data = JSON.stringify(response.data.data);
                this.ref = response.data.ref;
                this.type = this.typeItems.filter(v => (v.value == response.data.type))[0];
                this.status = this.statusItems.filter(v => (v.value == response.data.status))[0];
                this.createTime = response.data.createTime;
                this.updateTime = response.data.updateTime;
                //this.resetValidation();
                this.$nextTick(() => this.resetValidation());
                this.$emit('update-account', { accountNumber: this.number, accountId: this.id, holderId: this.holderId, balance: this.balance});
                //this.$store.dispatch("routerPushWrap", '/holder/' + response.data.holderId);
              }
              else{
                //this.$emit('update-account', {holderId: null});
                // this.$store.dispatch("routerPushWrap", {path: '/holders'});
                throw new Error("Ошибка получения данных");
              }
            })
            .then(null, (error) =>{
              //console.log(error);
              this.$store.dispatch("showMessage", { text: 'Ошибка получения account: ' + error.message, error: true });
              //this.$store.dispatch("routerPushWrap", { path: '/holders' });
            })
            .finally(() =>{
              this.$store.dispatch("setLoading", false);
            });
        }
        catch(e){
          this.$store.dispatch("showMessage", {text: e.message, error: true});
          this.$store.dispatch("setLoading", false);
        }
      },
      
      submit(){
        if (this.validate()){
            //console.log(a);
          this.$store.dispatch("setLoading", true);
            //const { sortBy, sortDesc, page, itemsPerPage } = this.options;
            const reqParams = {
              id: this.id,
              holderId: this.holderId,
              status: this.status.value,
              //data: this.data,
            };
            //console.log(payload);
          let reqMethod = '';
          if (!this.id){
            reqMethod = 'postHolderAccount';
          }
          else{
            reqMethod = 'putHolderAccount';
          }
          
          try{
              this.$store.dispatch(reqMethod, reqParams)
                .then((response) => {
                  //получить ид пользователя и перейти на пользователя
                  //console.log(response);
                  //{ name: 'user', params: { userId: 123 }}
                  if (reqMethod == 'postHolderAccount'){
                    //this.$store.dispatch("routerPushWrap", {name: "HolderAccount", params: { holder: response.data.holderId, param: response.data.accountId}});

                    //this.$store.dispatch("routerPushWrap", {path:'/holder/' + response.data.holderId});
                    //this.$store.dispatch("routerPushWrap", '/holder/' + response.data.holderId);
                    //this.partners = response.data.data.map((i) => ({id: i.id, name: i.shortName, holderRouteId: i.holderRouteId}));
                    //this.$emit('update-account', {accountId: response.data.accountId, holderId: response.data.holderId});
                  }
                  else if (reqMethod == 'putHolderAccount'){
                    this.$emit('update-account', {accountId: response.data.accountId, holderId: response.data.holderId});
                    this.get();
                  }
                })
                .then(null,(error) => {
                    console.log(error);
                    //this.$store.dispatch("showMessage", {text: 'Ошибка получения данных', error: true});
                })
                .finally(() => {
                  this.$store.dispatch("setLoading", false);
                });
          }
          catch(e){
            this.$store.dispatch("showMessage", {text: e.message, error: true});
            this.$store.dispatch("setLoading", false);
          }
        }
      },

      transliterate(word){
        if (!word) return "";
        // const a = {"Ё":"YO","Й":"I","Ц":"TS","У":"U","К":"K","Е":"E","Н":"N","Г":"G","Ш":"SH","Щ":"SCH","З":"Z","Х":"H","Ъ":"'","ё":"yo","й":"i","ц":"ts","у":"u","к":"k","е":"e","н":"n","г":"g","ш":"sh","щ":"sch","з":"z","х":"h","ъ":"'","Ф":"F","Ы":"I","В":"V","А":"a","П":"P","Р":"R","О":"O","Л":"L","Д":"D","Ж":"ZH","Э":"E","ф":"f","ы":"i","в":"v","а":"a","п":"p","р":"r","о":"o","л":"l","д":"d","ж":"zh","э":"e","Я":"Ya","Ч":"CH","С":"S","М":"M","И":"I","Т":"T","Ь":"'","Б":"B","Ю":"YU","я":"ya","ч":"ch","с":"s","м":"m","и":"i","т":"t","ь":"'","б":"b","ю":"yu"};
        const a = {"Ё":"YO","Й":"I","Ц":"TS","У":"U","К":"K","Е":"E","Н":"N","Г":"G","Ш":"SH","Щ":"SCH","З":"Z","Х":"H","Ъ":"","ё":"yo","й":"i","ц":"ts","у":"u","к":"k","е":"e","н":"n","г":"g","ш":"sh","щ":"sch","з":"z","х":"h","ъ":"","Ф":"F","Ы":"I","В":"V","А":"a","П":"P","Р":"R","О":"O","Л":"L","Д":"D","Ж":"ZH","Э":"E","ф":"f","ы":"i","в":"v","а":"a","п":"p","р":"r","о":"o","л":"l","д":"d","ж":"zh","э":"e","Я":"Ya","Ч":"CH","С":"S","М":"M","И":"I","Т":"T","Ь":"","Б":"B","Ю":"YU","я":"ya","ч":"ch","с":"s","м":"m","и":"i","т":"t","ь":"","б":"b","ю":"yu"," ":"", "\"":"", "'":""};

        return word.split('').map(function (char) {
          return (a[char] === undefined ? char : a[char]);
        }).join("");
      },

      validate(){
        //console.log();
        return this.$refs.form.validate();
      },

      reset(){
        this.$refs.form.reset();
      },
      
      resetValidation(){
        if (this.$refs['form']){
          this.$refs.form.resetValidation();
        }
      },
    },
  };
  
</script>

<style scoped>

</style>